<template>
	<div class="login">
		<div class="left">
			<div class="font1" :class="locale == 'en' ? 'eng_font1' : ''">{{ $t('sign.font1') }}</div>
			<div class="font2">{{ $t('sign.font2') }}</div>
			<img src="../../assets/login/left_back.png" alt="" />
		</div>
		<div class="right">
			<div class="title">{{ $t('sign.title') }}</div>
			<div class="tab">
				<div @click="iphoneState('yx')" :class="{ iphoneState: iphone === 'yx' }">
					{{ $t('login.email') }}
				</div>
				<!-- <div @click="iphoneState('phone')" :class="{ iphoneState: iphone === 'phone' }">Phone</div> -->
			</div>
			<div class="input input1">
				<el-input
					v-if="iphone == 'yx'"
					v-model="formLabelAlign.email"
					:placeholder="$t('login.email')"
				>
					<template #prefix>
						<img class="icon" src="../../assets/login/email.png" alt="" />
					</template>
				</el-input>
				<el-input v-else-if="iphone == 'phone'" v-model="formLabelAlign.phone" placeholder="Phone">
					<template #prefix>
						<img class="icon" src="../../assets/login/phone.png" alt="" />
					</template>
				</el-input>
			</div>
			<div class="input input2">
				<el-input v-model="formLabelAlign.code" :placeholder="$t('login.code')">
					<template #prefix>
						<img class="icon" src="../../assets/login/code.png" alt="" />
					</template>
					<template #append>
						<el-button v-if="!sms.disabled" color="#19d079" type="default" @click="send">
							<span style="color: #19d079">{{ $t('sign.send') }}</span>
						</el-button>
						<el-button v-else type="default" color="#f38301" disabled>
							<span style="color: #19d079">({{ sms.count }}S)</span>
						</el-button>
					</template>
				</el-input>
			</div>
			<div class="input input3">
				<!-- <el-input
					v-model="formLabelAlign.password"
					type="password"
					placeholder="Password"
					show-password
				>
					<template #prefix>
						<img class="icon" src="../../assets/login/pass.png" alt="" />
					</template>
				</el-input> -->
				<el-input
					v-model="formLabelAlign.password"
					:placeholder="$t('login.password')"
					:type="showPassword ? 'password' : 'text'"
				>
					<template #prefix>
						<img class="icon" src="../../assets/login/pass.png" alt="" />
					</template>
					<template #suffix>
						<span class="input_icon" @click="switchPassword">
							<!-- //icon图标 -->
							<img
								v-if="showPassword"
								src="../../assets/login/pass2.png"
								class="pass_icon"
								alt=""
							/>
							<img v-else src="../../assets/login/pass1.png" class="pass_icon" alt="" />
						</span>
					</template>
				</el-input>
			</div>
			<div class="input input4">
				<el-input
					v-model="formLabelAlign.forgot"
					:placeholder="$t('login.password2')"
					:type="showPassword ? 'password' : 'text'"
				>
					<template #prefix>
						<img class="icon" src="../../assets/login/pass.png" alt="" />
					</template>
					<template #suffix>
						<span class="input_icon" @click="switchPassword">
							<!-- //icon图标 -->
							<img
								v-if="showPassword"
								src="../../assets/login/pass2.png"
								class="pass_icon"
								alt=""
							/>
							<img v-else src="../../assets/login/pass1.png" class="pass_icon" alt="" />
						</span>
					</template>
				</el-input>
			</div>
			<el-button class="submit" @click="toSign"> {{ $t('sign.submit') }} </el-button>
			<div class="goOther">
				{{ $t('sign.goOther1') }}
				<span @click="login('login')">{{ $t('sign.goOther2') }}</span>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
// import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElLoading, ElMessage } from 'element-plus';
import { emailRegisterApi, emailSignApi } from '@/api/index';

const router = useRouter();
const { locale } = useI18n();

let iphone = ref('yx');
let input = ref('');

const showPassword = ref('text');
// 点击切换密码显示状态
const switchPassword = () => {
	showPassword.value = !showPassword.value;
};
// 注册参数
const formLabelAlign = reactive({
	email: '',
	phone: '',
	password: '',
	forgot: '',
	code: '',
	inviteCode: '',
});

// 验证码计时器
const sms = reactive({
	disabled: false,
	total: 60,
	count: 0,
});
// 计时器处理器
const timerHandler = () => {
	sms.count = sms.total;
	sms.disabled = true;
	let timer = setInterval(() => {
		if (sms.count > 1 && sms.count <= sms.total) {
			sms.count--;
		} else {
			sms.disabled = false;
			clearInterval(timer);
		}
	}, 1000);
};
// 推送
const send = () => {
	if (formLabelAlign.email === '') {
		if (locale.value == 'en') {
			ElMessage.error('The email is empty');
		} else {
			ElMessage.error('电子邮件为空');
		}
	} else {
		// 计时器处理器
		timerHandler();
		// 加载ui
		const loadingInstance1 = ElLoading.service({ fullscreen: true });
		// api 请求
		return emailRegisterApi({
			email: formLabelAlign.email,
		})
			.then((res) => {
				// 关闭加载
				loadingInstance1.close();
			})
			.catch(() => {
				// 关闭加载
				loadingInstance1.close();
			});
	}
};

// 注册
const toSign = () => {
	if (formLabelAlign.email === '') {
		if (locale.value == 'en') {
			ElMessage.error('The email is empty');
		} else {
			ElMessage.error('电子邮件为空');
		}
	} else if (formLabelAlign.code === '') {
		if (locale.value == 'en') {
			ElMessage.error('The verification code is empty');
		} else {
			ElMessage.error('验证码为空');
		}
	} else if (formLabelAlign.password === '') {
		if (locale.value == 'en') {
			ElMessage.error('Password is empty');
		} else {
			ElMessage.error('密码为空');
		}
	} else if (formLabelAlign.forgot === '') {
		if (locale.value == 'en') {
			ElMessage.error('The double-check password is empty');
		} else {
			ElMessage.error('二次确认密码为空');
		}
	} else if (formLabelAlign.forgot != formLabelAlign.password) {
		if (locale.value == 'en') {
			ElMessage.error('Two passwords are inconsistent. Please check');
		} else {
			ElMessage.error('两次密码不一致请检查');
		}
	} else if (formLabelAlign.forgot === formLabelAlign.password) {
		let url = window.location.href;
		if (url.lastIndexOf('inviteCode=') != -1) {
			let index = url.lastIndexOf('inviteCode=');
			formLabelAlign.inviteCode = url.substring(index + 11, url.length);
		}
		// 加载ui
		const loadingInstance1 = ElLoading.service({ fullscreen: true });
		// api 请求
		return emailSignApi({
			email: formLabelAlign.email,
			password: formLabelAlign.password,
			code: formLabelAlign.code,
			inviteCode: formLabelAlign.inviteCode,
			// inviteCode: localStorage.getItem('inviteCode'),
		})
			.then((res) => {
				// 关闭加载
				loadingInstance1.close();
				if (res.code == 200) {
					router.push('login');
				} else {
					ElMessage.error(res.msg);
				}
			})
			.catch(() => {
				// 关闭加载
				loadingInstance1.close();
			});
	}
};
const login = (type) => {
	router.push(type);
};

const iphoneState = (state) => {
	iphone.value = state;
	formLabelAlign.email = null;
	formLabelAlign.phone = null;
	formLabelAlign.password = null;
	formLabelAlign.forgot = null;
	formLabelAlign.code = null;
	sms.disabled = false;
	sms.count = 0;
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.login {
		width: 100%;
		// height: calc(100vh);
		padding: 120px 150px 120px;
		display: flex;
		justify-content: space-between;
		background: #ffffff;
		font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
		.left {
			width: 720px;
			text-align: center;
			.font1 {
				margin-bottom: 21px;
				font-size: 53px;
				color: #333333;
				font-weight: 600;
			}
			.eng_font1 {
				font-size: 44px;
			}
			.font2 {
				margin-bottom: 68px;
				font-size: 35px;
				color: #333333;
			}
			img {
				width: 100%;
				// height: 100%;
			}
		}
		.right {
			width: 747px;
			height: 815px;
			background: #f6f8fc;
			border-radius: 20px;
			padding: 40px 50px 0;
			.title {
				font-size: 50px;
				font-weight: 600;
				color: #333333;
				text-align: center;
				margin-bottom: 54px;
			}
			.tab {
				display: flex;
				width: 100%;
				height: 53px;
				// padding: 0 50px;
				font-weight: 600;
				font-size: 25px;
				color: #666666;
				text-align: center;
				// justify-content: space-between;
				border-bottom: 1px solid #4a4a4a;
				margin-bottom: 51px;
				div {
					border: 0;
					position: relative;
					margin-right: 75px;
				}
				.iphoneState {
					font-size: 25px;
					color: #333333;
				}
				.iphoneState:after {
					content: '';
					position: absolute;
					left: 50%;
					bottom: -1px;
					height: 8px;
					width: 66px;
					text-align: center;
					transform: translate(-50%, -1%);
					// background-color: #f8de4d;
					z-index: 9;
					background: #19d079;
				}
			}
			.input {
				width: 100%;
				height: 75px;
				margin-bottom: 21px;
				.icon {
					width: 30px;
				}
				.el-input {
					width: 100%;
					height: 100%;
					border: 0;
					border-radius: 4px;
					border: 1px solid #4a4a4a;
					color: #333333;
				}
			}
			.input4 {
				margin-bottom: 25px;
			}
			.submit {
				width: 100%;
				height: 75px;
				background: #19d079;
				border-radius: 7px;
				font-size: 30px;
				color: #333333;
				margin-bottom: 25px;
			}
			.goOther {
				text-align: right;
				font-size: 20px;
				color: #333333;
				span {
					color: #19d079;
					cursor: pointer;
				}
			}
		}
	}
	.input_icon {
		cursor: pointer;
		.pass_icon {
			width: 20px;
		}
	}
}
@media screen and (max-width: 768px) {
	.login {
	}
}
</style>

<style lang="scss">
.el-input__inner {
	font-size: 20px;
	font-weight: 600;
}
.el-input {
	background: #f6f8fc !important;
	--el-input-placeholder-color: #666666 !important;
}
.el-input__wrapper {
	background: #f6f8fc !important;
}
.input2 {
	.el-input-group__append {
		// color: #19d079;
		background: #f6f8fc;
	}
	.el-input__wrapper {
		box-shadow: none;
	}
}
</style>
